export const POPULATE_RESERVATIONS = 'RESERVATIONS@POPULATE'
export const ADD_RESERVATION = 'RESERVATIONS@ADD'
export const EDIT_RESERVATION = 'RESERVATIONS@EDIT'
export const REMOVE_RESERVATION = 'RESERVATIONS@REMOVE'

export function reservationsHaveBeenLoaded(reservations) {
	return {
		type: POPULATE_RESERVATIONS,
		reservations,
	}
}

export function reservationCreated(reservation) {
	return {
		type: ADD_RESERVATION,
		reservation,
	}
}

export function reservationRemoved(reservation) {
	return {
		type: REMOVE_RESERVATION,
		reservation,
	}
}

export function reservationUpdated(reservation) {
	return {
		type: EDIT_RESERVATION,
		reservation,
	}
}

export function reservationsReducer(state = [], action) {
	switch (action.type) {
		case POPULATE_RESERVATIONS: {
			return action.reservations
		}

		case ADD_RESERVATION: {
			return [...state, action.reservation]
		}

		case EDIT_RESERVATION: {
			return state.map(r => (r.id !== action.reservation.id ? r : action.reservation))
		}

		case REMOVE_RESERVATION: {
			return state.filter(r => +r.id !== +action.reservation)
		}

		default: {
			return state
		}
	}
}
