import http from 'axios'
import localforage from 'localforage'
import { omit } from 'ramda'
import { setUser } from './user.state'
import { config } from '../../../core/config.core'

export function login(data) {
	return async dispatch => {
		try {
			const response = await http.post(`${config.server}/login`, data)
			const user = response.data

			await localforage.setItem('prenotazioni_user', user)

			http.defaults.headers['-x-auth-id'] = user.id
			http.defaults.headers['-x-auth-token'] = user.token.hash

			dispatch(setUser(response.data))
		} catch {}
	}
}

export function logout() {
	return async dispatch => {
		try {
			await http.post(`${config.server}/logout`)
			await localforage.removeItem('prenotazioni_user')

			http.defaults.headers = omit(['-x-auth-id', '-x-auth-token'], http.defaults.headers)

			dispatch(setUser(null))
		} catch {}
	}
}
