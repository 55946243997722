import { ReservationStatus } from '../types/reservation-status.type'

const ReservationStatusMap = new Map([
	[ReservationStatus.All, 'Tutte'],
	[ReservationStatus.Created, 'Nuova'],
	[ReservationStatus.Accepted, 'In attesa di pagamento'],
	[ReservationStatus.Denied, 'Rifiutata'],
	[ReservationStatus.Confirmed, 'Confermata'],
	[ReservationStatus.Cancelled, 'Annullata'],
	[ReservationStatus.Completed, 'Checkout Effettuato'],
])

export const printReservationStatusLabel = status => {
	return ReservationStatusMap.get(status)
}
