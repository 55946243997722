import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { useForm } from '../../common/hooks/use-form.hook'
import { login } from '../../state/auth/auth.effects'
import { Backdrop } from './backdrop.component'

export const Login = function() {
	const [data, setData] = useForm({ email: '', password: '' })
	const dispatch = useDispatch()
	const user = useSelector(state => state.auth.user)

	async function handleLogin() {
		await dispatch(login(data))
	}

	return (
		<Backdrop>
			{user && <Redirect to="/" />}
			<Card elevation={1} style={{ minWidth: 400 }}>
				<h3>Effettua l'accesso</h3>
				<FormGroup label="Email">
					<InputGroup
						name="email"
						value={data.email}
						onChange={setData}
						placeholder="Email"
					/>
				</FormGroup>
				<FormGroup label="Password">
					<InputGroup
						name="password"
						type="password"
						value={data.password}
						onChange={setData}
						placeholder="Password"
					/>
				</FormGroup>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button icon="log-in" onClick={handleLogin}>
						Accedi
					</Button>
				</div>
			</Card>
		</Backdrop>
	)
}
