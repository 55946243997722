import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Login } from './views/auth/login.component'
import { Logout } from './views/auth/logout.component'
import { Dashboard } from './views/dashboard/dashboard.component'

export const App = function() {
	return (
		<Switch>
			<Route path="/login" component={Login} />
			<Route path="/logout" component={Logout} />
			<Route path="/" component={Dashboard} />
		</Switch>
	)
}
