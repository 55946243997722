import { useSelector, useDispatch } from 'react-redux'
import { loadReservations } from '../../state/reservations/reservations.effects'
import { useEffect } from 'react'

export const useReservations = () => {
	const reservations = useSelector(state => state.reservations)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadReservations())
	}, [dispatch])

	return reservations
}
