import React from 'react'
import { Dialog, Button, Intent } from '@blueprintjs/core'

export const DeleteReservationDialog = ({
	reservation,
	isOpen,
	onClose,
	onDeleteReservation,
	loading,
}) => (
	<Dialog title="Elimina Prenotazione" icon="trash" isOpen={isOpen} onClose={onClose}>
		<div className="bp3-dialog-body">Sei sicuro di voler eliminare la prenotazione?</div>
		<div className="bp3-dialog-footer">
			<div className="bp3-dialog-footer-actions">
				<Button text="Annulla" onClick={onClose} disabled={loading} />
				<Button
					text="Elimina Prenotazione"
					intent={Intent.DANGER}
					onClick={() => onDeleteReservation(reservation)}
					disabled={loading}
				/>
			</div>
		</div>
	</Dialog>
)
