import { Intent } from '@blueprintjs/core'
import http from 'axios'
import localforage from 'localforage'
import React from 'react'
import ReactDOM from 'react-dom'

import { AppToaster } from './app/common/components/app-toaster.component'
import { setUser } from './app/state/auth/user.state'
import { store } from './app/state/store'
import { setLoadingStart, setLoadingStop } from './app/state/ui/loading.state'
import { config } from './core/config.core'
import { Root } from './root.component'

async function bootstrap() {
	require('./index.css')
	require('normalize.css/normalize.css')
	require('@blueprintjs/core/lib/css/blueprint.css')
	require('@blueprintjs/icons/lib/css/blueprint-icons.css')
	require('@blueprintjs/datetime/lib/css/blueprint-datetime.css')
	require('flag-icon-css/css/flag-icon.css')

	const user = await localforage.getItem('prenotazioni_user')
	if (user) {
		try {
			await http.get(`${config.server}/hello`, {
				headers: {
					'-x-auth-id': user.id,
					'-x-auth-token': user.token.hash,
				},
			})

			http.defaults.headers = {
				...http.defaults.headers,
				'-x-auth-id': user.id,
				'-x-auth-token': user.token.hash,
			}

			store.dispatch(setUser(user))
		} catch (ex) {
			console.log('Error', ex)
			await localforage.removeItem('prenotazioni_user')
		}
	}

	http.interceptors.request.use(req => {
		store.dispatch(setLoadingStart())

		return req
	})

	http.interceptors.response.use(
		res => {
			store.dispatch(setLoadingStop())

			return res.data
		},
		error => {
			store.dispatch(setLoadingStop())

			if (!error.response) {
				return Promise.reject(error)
			}

			if (error.response.status === 401) {
				AppToaster.show({
					message: 'Le credenziali inserite non sono corrette',
					intent: Intent.WARNING,
				})
			}

			if (error.response.status === 403) {
				AppToaster.show({
					message:
						'Non si è autorizzati ad accedere al contenuto richiesto',
					intent: Intent.DANGER,
				})
			}

			if (error.response.status === 422) {
				AppToaster.show({
					message: 'I dati inseriti non sono validi',
					intent: Intent.DANGER,
				})
			}

			if (error.response.status === 500) {
				AppToaster.show({
					message:
						'Si è verificato un errore durante la comunicazione con il server',
					intent: Intent.DANGER,
				})
			}

			return Promise.reject(error)
		},
	)

	ReactDOM.render(<Root />, document.getElementById('root'))
}

bootstrap()
