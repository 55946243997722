export const LOADING_START = 'UI@LOADING_START'
export const LOADING_STOP = 'UI@LOADING_STOP'

export function setLoadingStart() {
	return {
		type: LOADING_START,
	}
}

export function setLoadingStop() {
	return {
		type: LOADING_STOP,
	}
}

export function loadingReducer(state = 0, action) {
	switch (action.type) {
		case LOADING_START: {
			return state + 1
		}

		case LOADING_STOP: {
			if (state - 1 < 0) {
				return 0
			}

			return state - 1
		}

		default: {
			return state
		}
	}
}
