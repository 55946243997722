import React from 'react'

import { ReservationStatus } from '../../common/types/reservation-status.type'

const getStatusColor = (status, hasDeposit) => {
	switch (status) {
		case ReservationStatus.Created: {
			return 'yellow'
		}

		case ReservationStatus.Accepted: {
			return 'orange'
		}

		case ReservationStatus.Confirmed: {
			return hasDeposit ? 'green' : 'green'
		}

		case ReservationStatus.Denied: {
			return 'red'
		}

		case ReservationStatus.Cancelled: {
			return 'red'
		}

		case ReservationStatus.Completed: {
			return 'pink'
		}

		default: {
			return '#333'
		}
	}
}

export const ReservationStatusIcon = ({ status, hasDeposit }) => (
	<div
		style={{
			border: '1px solid #333',
			boxShadow: '0 1px 3px #333',
			marginTop: 3,
			borderRadius: 1000,
			height: 16,
			width: 16,
			backgroundColor: getStatusColor(status, hasDeposit),
		}}
	></div>
)
