import { filterList } from '../utils/filter-list.util'
import { useAsyncValue } from './use-async-value.hook'
import { useMemo } from 'react'

export function useFilter(rules, filter, data) {
	const filteredList = useMemo(() => {
		return filterList(rules, filter, data)
	}, [rules, filter, data])

	return filteredList
}
