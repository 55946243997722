import { Button, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core'
import React from 'react'

import { useForm } from '../../../common/hooks/use-form.hook'
import { useValidation } from '../../../common/hooks/use-validation.hook'
import { useEffect } from 'react'

export const ConfirmReservationDialog = function({
	reservation,
	onConfirmReservation,
	isOpen,
	onClose,
	askDeposit,
	loading,
}) {
	const [data, onChange] = useForm({
		deposit: 15,
	})
	const [validationStatus, { validate, resetValidationStatus }] = useValidation({
		deposit: v => v && +v > 0,
	})

	useEffect(() => {
		if (!reservation) {
			return
		}

		onChange({ deposit: 15 * reservation.occupants })
	}, [reservation])

	useEffect(() => {
		resetValidationStatus()
	}, [data])

	async function handleConfirmReservationButtonClick() {
		if (!validate(data)) {
			return
		}

		onConfirmReservation(reservation, data.deposit)
	}

	return (
		<Dialog isOpen={isOpen} onClose={onClose} title="Conferma Prenotazione" icon="thumbs-up">
			{askDeposit && reservation && (
				<>
					<div className="bp3-dialog-body">
						<FormGroup
							label="Caparra richiesta"
							intent={
								validationStatus.deposit === false ? Intent.DANGER : Intent.NONE
							}
							helperText={
								validationStatus.deposit === false &&
								'La caparra deve essere numerica e maggiore o uguale a 0'
							}
						>
							<InputGroup
								leftIcon="euro"
								name="deposit"
								onChange={onChange}
								value={data.deposit}
							/>
							<p>
								La caparra è calcolata come{' '}
								{Number(data.deposit / reservation.occupants).toFixed(2)} &euro; per
								ciascuno dei {reservation.occupants} ospiti.
							</p>
						</FormGroup>
					</div>
					<div className="bp3-dialog-footer">
						<div className="bp3-dialog-footer-actions">
							<Button
								text="Annulla"
								intent={Intent.NONE}
								onClick={onClose}
								disabled={loading}
							/>
							<Button
								text="Conferma Prenotazione"
								intent={Intent.SUCCESS}
								onClick={() => handleConfirmReservationButtonClick(data)}
								disabled={loading}
							/>
						</div>
					</div>
				</>
			)}
			{!askDeposit && (
				<>
					<div className="bp3-dialog-body">Confermi la prenotazione senza caparra?</div>
					<div className="bp3-dialog-footer">
						<div className="bp3-dialog-footer-actions">
							<Button
								text="Annulla"
								intent={Intent.NONE}
								onClick={onClose}
								disabled={loading}
							/>
							<Button
								text="Conferma Prenotazione"
								intent={Intent.SUCCESS}
								onClick={() => handleConfirmReservationButtonClick({ deposit: 0 })}
								disabled={loading}
							/>
						</div>
					</div>
				</>
			)}
		</Dialog>
	)
}
