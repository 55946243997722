export const validate = (rules, data) => {
	if (Object.keys(rules).length === 0) {
		return data
	}

	const validationStatus = {}
	const validated = Object.keys(rules).reduce((result, key) => {
		validationStatus[key] = !!rules[key].call(undefined, data[key], data)

		return result === false ? false : validationStatus[key]
	}, true)

	return { validationStatus, validated }
}
