import { useState, useCallback, useMemo } from 'react'

function handleChange({ data, updateData }, ...args) {
	if (args.length === 0) {
		return
	}

	const [maybeEvent] = args

	if (maybeEvent.nativeEvent && maybeEvent.nativeEvent instanceof Event) {
		const [event] = args

		const update = {
			[event.target.name]:
				event.target.type === 'checkbox'
					? event.target.checked
					: event.target.value,
		}

		updateData(update)

		return
	}

	if (typeof maybeEvent === 'object') {
		const [data, shouldReplaceState] = args

		updateData(data, shouldReplaceState)

		return
	}

	const [name, value] = args
	const update = {
		[name]: value,
	}

	updateData(update)
}

export function useForm(initialValue = {}, middleware) {
	const middlewareFn = useMemo(
		() => (typeof middleware === 'function' ? middleware : data => data),
		[middleware],
	)
	const initialData = useMemo(() => middlewareFn(initialValue), [
		initialValue,
		middlewareFn,
	])

	const [data, setData] = useState(initialData)

	const updateData = useCallback(
		(update, replace = false) => {
			setData(data =>
				!replace
					? middlewareFn({ ...data, ...update })
					: middlewareFn(update),
			)
		},
		[middlewareFn],
	)

	const onChange = useCallback(
		(...args) => {
			handleChange({ data, updateData }, ...args)
		},
		[data, updateData],
	)

	return [data, onChange]
}

export function useSubform(Form, path) {
	const [data, onChange] = Form
	const value = data[path] || {}

	function onChangeSubform(...args) {
		const boundOnChange = (nestedData, flag) => {
			onChange(
				{
					...data,
					[path]: {
						...value,
						...nestedData,
					},
				},
				flag,
			)
		}

		handleChange({ data: value, updateData: boundOnChange }, ...args)
	}

	return [value, onChangeSubform]
}

export function useList(Form, path, identifier = i => i) {
	const [data, onChange] = Form
	const dataPath = useMemo(() => {
		return data[path]
	}, [data, path])

	const items = useMemo(() => {
		return Array.isArray(dataPath) ? dataPath : []
	}, [dataPath])

	const boundOnChange = (list, flag) => {
		onChange(path, list)
	}

	const onAdd = item => {
		boundOnChange([...items, item])
	}

	const onEdit = (item, update, replace = false) => {
		const updatedList = items.map(_i => {
			if (identifier(_i) === identifier(item)) {
				return replace ? update : { ...item, ...update }
			}

			return _i
		})

		boundOnChange(updatedList)
	}

	const onRemove = item => {
		const updatedList = items.filter(_i => {
			return identifier(_i) !== identifier(item)
		})

		boundOnChange(updatedList)
	}

	return [items, { onAdd, onEdit, onRemove }]
}
