import { toPairs } from 'ramda'
import { isSet } from './is-set.util'

function callRule([key, rule], filter, item) {
	if (!filter.hasOwnProperty(key) || !isSet(filter[key])) {
		return true
	}

	return rule.call(undefined, item[key], filter[key], item, filter)
}

export function filterList(rules, filter, items) {
	const rulePairs = toPairs(rules)

	const result = []
	for (let item of items) {
		let passes = true
		for (let pair of rulePairs) {
			passes = callRule(pair, filter, item)

			if (!passes) break
		}

		if (passes) {
			result.push(item)
		}
	}

	return result

	// return new Promise(resolve => {
	// 	const rulePairs = toPairs(rules)

	// 	const result = items.filter(item => {
	// 		return rulePairs
	// 			.map(([key, rule]) => {
	// 				if (!filter.hasOwnProperty(key) || !isSet(filter[key])) {
	// 					return true
	// 				}

	// 				return rule.call(undefined, item[key], filter[key], item)
	// 			})
	// 			.reduce((r, n) => {
	// 				if (!r || !n) {
	// 					return false
	// 				}

	// 				return true
	// 			}, true)
	// 	})

	// 	resolve(result)
	// })
}
