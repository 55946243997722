import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { userReducer } from './auth/user.state'
import { loadingReducer } from './ui/loading.state'
import { reservationsReducer } from './reservations/reservation.state'

const rootReducer = combineReducers({
	auth: combineReducers({
		user: userReducer,
	}),
	ui: combineReducers({
		loading: loadingReducer,
	}),
	reservations: reservationsReducer,
})

export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(thunk)),
)
