import styled from 'styled-components'

export const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.bp3-form-group {
		flex-grow: 1;
		margin: 0 10px;

		.bp3-popover-target {
			width: 100%;
		}
	}
`
