import React from 'react'
import { Provider } from 'react-redux'
import { store } from './app/state/store'
import { App } from './app/app.component'
import { BrowserRouter } from 'react-router-dom'

export const Root = function() {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
	)
}
