import {
	Button,
	ButtonGroup,
	Card,
	Checkbox,
	FormGroup,
	InputGroup,
} from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import moment from 'moment'
import React from 'react'
import { LocaleUtils } from 'react-day-picker'

import { printReservationStatusLabel } from '../../common/utils/print-reservation-status-label.util'
import { FilterContainer } from './filter-container.component'
import {
	MONTHS,
	WEEKDAYS_LONG,
	WEEKDAYS_SHORT,
} from '../../common/types/locale'

export const Filter = function ({ filter, onChange }) {
	return (
		<Card style={{ margin: 10 }}>
			<h4 className="bp3-heading">Filtra Prenotazioni</h4>
			<FilterContainer>
				<div className="bp3-form-group">
					<FormGroup label="ID">
						<InputGroup
							name="id"
							onChange={onChange}
							value={filter.id}
						/>
					</FormGroup>
					<FormGroup label="Nome e Cognome">
						<InputGroup
							name="name"
							onChange={onChange}
							value={filter.name}
						/>
					</FormGroup>
				</div>
				<div className="bp3-form-group">
					<div style={{ display: 'flex' }}>
						<FormGroup label="Data Inizio">
							<DateInput
								value={filter.reservation_start}
								parseDate={(date) => moment(date).toDate()}
								formatDate={(date) =>
									moment(date).format('DD/MM/YYYY')
								}
								onChange={(date) => {
									onChange({
										reservation_start: moment(date)
											.startOf('day')
											.toDate(),
										reservation_end: moment(date)
											.startOf('day')
											.add(1, 'day')
											.toDate(),
									})
								}}
								locale="it"
								dayPickerProps={{
									months: MONTHS,
									weekdaysLong: WEEKDAYS_LONG,
									weekdaysShort: WEEKDAYS_SHORT,
								}}
								format="DD/MM/YYYY"
								minDate={moment()
									.subtract(10, 'years')
									.toDate()}
								maxDate={moment().add(10, 'years').toDate()}
							/>
							<Checkbox
								label="Mostra solo giorno selezionato"
								name="date_exclusive"
								onChange={(e) => {
									onChange({
										date_exclusive: e.target.checked,
										show_period: false,
										reservation_end: e.target.checked
											? moment(filter.reservation_start)
													.startOf('day')
													.add(1, 'day')
													.toDate()
											: filter.reservation_end,
									})
								}}
								checked={filter.date_exclusive}
							/>
						</FormGroup>
						{!filter.date_exclusive && (
							<FormGroup label="Data Fine">
								<DateInput
									value={filter.reservation_end}
									parseDate={(date) => moment(date).toDate()}
									formatDate={(date) =>
										moment(date).format('DD/MM/YYYY')
									}
									onChange={(date) => {
										onChange(
											'reservation_end',
											moment(date)
												.startOf('day')
												.toDate(),
										)
									}}
									locale="it"
									dayPickerProps={{
										months: MONTHS,
										weekdaysLong: WEEKDAYS_LONG,
										weekdaysShort: WEEKDAYS_SHORT,
									}}
									format="DD/MM/YYYY"
									minDate={moment(filter.reservation_start)
										.startOf('day')
										.add(1, 'day')
										.toDate()}
									maxDate={moment(filter.reservation_start)
										.startOf('day')
										.add(100, 'years')
										.toDate()}
								/>
								<Checkbox
									label="Mostra prenotazioni nel periodo selezionato"
									name="show_period"
									onChange={onChange}
									checked={filter.show_period}
								/>
							</FormGroup>
						)}
					</div>
					<ButtonGroup className="bp3-fill">
						<Button
							minimal
							text="Precedente"
							icon="step-backward"
							onClick={() => {
								onChange({
									reservation_start: moment(
										filter.reservation_start,
									)
										.startOf('day')
										.subtract(1, 'day')
										.toDate(),
									reservation_end: moment(
										filter.reservation_start,
									)
										.startOf('day')
										.toDate(),
								})
							}}
						/>
						<Button
							minimal
							text="Ieri"
							icon="caret-left"
							onClick={() => {
								onChange({
									reservation_start: moment()
										.startOf('day')
										.subtract(1, 'day')
										.toDate(),
									reservation_end: moment()
										.startOf('day')
										.toDate(),
								})
							}}
						/>
						<Button
							minimal
							text="Oggi"
							onClick={() => {
								onChange({
									reservation_start: moment()
										.startOf('day')
										.toDate(),
									reservation_end: moment()
										.startOf('day')
										.add(1, 'day')
										.toDate(),
								})
							}}
						/>
						<Button
							minimal
							text="Domani"
							rightIcon="caret-right"
							onClick={() => {
								onChange({
									reservation_start: moment()
										.startOf('day')
										.add(1, 'day')
										.toDate(),
									reservation_end: moment()
										.startOf('day')
										.add(2, 'days')
										.toDate(),
								})
							}}
						/>
						<Button
							minimal
							text="Successivo"
							rightIcon="step-forward"
							onClick={() => {
								onChange({
									reservation_start: moment(
										filter.reservation_start,
									)
										.startOf('day')
										.add(1, 'day')
										.toDate(),
									reservation_end: moment(
										filter.reservation_start,
									)
										.startOf('day')
										.add(2, 'days')
										.toDate(),
								})
							}}
						/>
					</ButtonGroup>
				</div>
				<FormGroup label="Stato Prenotazione">
					<div className="bp3-select bp3-fill">
						<select
							name="status"
							onChange={onChange}
							value={filter.status}
						>
							{[-1, 0, 1, 2, 3, 4, 5].map((i) => (
								<option value={i} key={i}>
									{printReservationStatusLabel(i)}
								</option>
							))}
						</select>
					</div>
				</FormGroup>
			</FilterContainer>
		</Card>
	)
}
