import {
	sort,
	prop,
	path,
	ascend,
	descend,
	toLower,
	pipe,
	ifElse,
	is,
	identity,
} from 'ramda'
import { useEffect, useState } from 'react'

export const SortDirection = {
	Ascending: ascend,
	Descending: descend,
}

export const useSort = (items, predicate, direction) => {
	const [sortedItems, setSortedItems] = useState(items || [])

	useEffect(() => {
		if (typeof predicate === 'function') {
			const sortByPredicate = sort(direction(predicate))

			setSortedItems(sortByPredicate(items))
			return
		}

		if (Array.isArray(predicate)) {
			const sortByPath = sort(direction(path(predicate)))

			setSortedItems(sortByPath(items))
			return
		}

		const sortByProp = sort(
			direction(
				pipe(prop(predicate), ifElse(is(String), toLower, identity)),
			),
		)

		setSortedItems(sortByProp(items))
		return
	}, [items, predicate, direction])

	return sortedItems
}
