import { Button, ButtonGroup, Icon } from '@blueprintjs/core'
import React, { useCallback, useMemo } from 'react'

const MAX_PAGES = 5

export const Paginator = ({ activePage, pageCount, onSetActivePage }) => {
	const shownPages = useMemo(() => {
		return pageCount > MAX_PAGES ? MAX_PAGES : pageCount
	}, [pageCount])

	const threshold = useMemo(() => {
		let threshold =
			activePage - Math.floor(MAX_PAGES / 2) > 0 ? activePage - Math.floor(MAX_PAGES / 2) : 0

		if (activePage > pageCount - MAX_PAGES) {
			threshold = pageCount - MAX_PAGES
		}

		return threshold > 0 ? threshold : 0
	}, [activePage, pageCount])

	const handleSetActivePage = useCallback(
		function(page) {
			if (page < 0) {
				return onSetActivePage(0)
			}

			if (page > pageCount - 1) {
				return onSetActivePage(pageCount - 1)
			}

			return onSetActivePage(page)
		},
		[onSetActivePage, pageCount],
	)

	if (pageCount < 2) {
		return null
	}

	return (
		<ButtonGroup className="justify-content-end">
			<Button onClick={() => handleSetActivePage(0)}>
				<Icon icon="double-chevron-left" />
			</Button>
			<Button onClick={() => handleSetActivePage(activePage - 1)}>
				<Icon icon="chevron-left" />
			</Button>
			{threshold + activePage >= MAX_PAGES - 1 && (
				<Button onClick={() => handleSetActivePage(activePage - MAX_PAGES)}>...</Button>
			)}
			{Array.from(Array(shownPages)).map((_, i) => {
				return (
					<Button
						key={i + threshold}
						active={i + threshold === activePage}
						onClick={() => handleSetActivePage(i + threshold)}
					>
						{i + 1 + threshold}
					</Button>
				)
			})}
			{threshold + MAX_PAGES < pageCount && (
				<Button onClick={() => handleSetActivePage(activePage + MAX_PAGES)}>...</Button>
			)}
			<Button onClick={() => handleSetActivePage(activePage + 1)}>
				<Icon icon="chevron-right" />
			</Button>
			<Button onClick={() => handleSetActivePage(pageCount - 1)}>
				<Icon icon="double-chevron-right" />
			</Button>
		</ButtonGroup>
	)
}
