import React from 'react'
import { Dialog, Button, Intent } from '@blueprintjs/core'

export const ConfirmCheckoutDialog = ({
	reservation,
	isOpen,
	onClose,
	onCheckoutReservation,
	loading,
}) => (
	<Dialog title="Completa Prenotazione" icon="eject" isOpen={isOpen} onClose={onClose}>
		<div className="bp3-dialog-body">
			Confermi il checkout degli ospiti e la chiusura della prenotazione?
		</div>
		<div className="bp3-dialog-footer">
			<div className="bp3-dialog-footer-actions">
				<Button text="Annulla" onClick={onClose} disabled={loading} />
				<Button
					text="Conferma Checkout"
					intent={Intent.SUCCESS}
					onClick={() => onCheckoutReservation(reservation)}
					disabled={loading}
				/>
			</div>
		</div>
	</Dialog>
)
