import {
	Button,
	ButtonGroup,
	Navbar,
	NavbarGroup,
	NavbarHeading,
	Intent,
} from '@blueprintjs/core'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import { useForm } from '../../common/hooks/use-form.hook'
import { ReservationStatus } from '../../common/types/reservation-status.type'
import {
	loadReservations,
	saveReservation,
	resendPaymentEmailForReservation,
	deleteReservation,
} from '../../state/reservations/reservations.effects'
import { Filter } from './filter.component'
import { Topbar } from './topbar.component'
import { Reservations } from './reservations.component'
import { useFilter } from '../../common/hooks/use-filter.hook'
import { splitEvery } from 'ramda'
import { useState } from 'react'
import { ConfirmReservationDialog } from './dialogs/confirm-reservation-dialog.component'
import { DenyReservationDialog } from './dialogs/deny-reservation-dialog.component'
import { EditReservationDialog } from './dialogs/edit-reservation-dialog.component'
import { ConfirmCheckoutDialog } from './dialogs/confirm-checkout-dialog.component'
import { ResendPaymentEmailDialog } from './dialogs/resend-payment-email-dialog.component'
import { AppToaster } from '../../common/components/app-toaster.component'
import { SummaryCards } from './summary-cards.component'
import { useReservations } from './use-reservations.hook'
import { PaymentListDialog } from './dialogs/payment-list-dialog.component'
import { useSort, SortDirection } from '../../common/hooks/use-sort.hook'

const INITIAL_FILTER = {
	id: '',
	name: '',
	reservation_start: moment().startOf('day').toDate(),
	reservation_end: moment().startOf('day').add(1, 'day').toDate(),
	show_period: false,
	status: ReservationStatus.All,
	date_exclusive: true,
}

const FilterRules = {
	id: (v, f) => (f ? +v === +f : true),
	name: (v, f) => v?.toLowerCase().includes(f?.toLowerCase()),
	status: (v, f) => (+f !== ReservationStatus.All ? +v === +f : true),
	reservation_start: (v, f, i, filter) => {
		if (filter.date_exclusive) {
			return moment(i.reservation_start)
				.startOf('day')
				.isSame(moment(filter.reservation_start).startOf('day'))
		}

		if (filter.show_period) {
			return (
				moment(filter.reservation_start)
					.startOf('day')
					.isSameOrAfter(
						moment(i.reservation_start).startOf('day'),
					) &&
				moment(filter.reservation_start)
					.startOf('day')
					.isBefore(moment(i.reservation_end).startOf('day'))
			)
		}

		return moment(i.reservation_start)
			.startOf('day')
			.isSameOrAfter(moment(filter.reservation_start).startOf('day'))
	},
	reservation_end: (v, f, i, filter) => {
		if (filter.date_exclusive) {
			return true
		}

		if (filter.show_period) {
			return true
		}

		console.log(moment(i.reservation_end), moment(filter.reservation_end))

		return moment(i.reservation_end)
			.startOf('day')
			.isSameOrBefore(moment(filter.reservation_end).endOf('day'))
	},
}

export const Dashboard = function () {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth.user)
	const loading = useSelector((state) => state.ui.loading)
	const history = useHistory()
	const reservations = useReservations()
	const [filter, setFilter] = useForm(INITIAL_FILTER)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useForm({
		predicate: 'created_at',
		direction: SortDirection.Ascending,
	})

	const [confirmReservation, setConfirmReservation] = useState(null)
	const [denyReservation, setDenyReservation] = useState(null)
	const [editReservation, setEditReservation] = useState(null)
	const [completeReservation, setCompleteReservation] = useState(null)
	const [resendPaymentEmail, setResendPaymentEmail] = useState(null)
	const [shouldAskForDeposit, setShouldAskForDeposit] = useState(false)
	const [showPayments, setShowPayments] = useState(false)

	function handleLogout() {
		history.push('/logout')
	}

	function handleShowPayments() {
		setShowPayments(true)
	}

	function handleFilterNewReservations() {
		setFilter(
			{
				name: '',
				reservation_start: moment()
					.startOf('day')
					.subtract(10, 'year')
					.toDate(),
				reservation_end: moment()
					.startOf('day')
					.add(10, 'year')
					.toDate(),
				status: ReservationStatus.Created,
				show_period: false,
				date_exclusive: false,
			},
			true,
		)
	}

	function handleResetFilter() {
		setFilter(INITIAL_FILTER, true)
	}

	function handleReloadReservations() {
		dispatch(loadReservations())
	}

	async function handleConfirmReservation(reservation, deposit = 0) {
		await dispatch(
			saveReservation({
				...reservation,
				deposit,
				status:
					deposit > 0
						? ReservationStatus.Accepted
						: ReservationStatus.Confirmed,
			}),
		)

		if (deposit == 0) {
			AppToaster.show({
				message:
					'Prenotazione accettata correttamente, il cliente riceverà una email di notifica',
			})
		}

		if (deposit > 0) {
			AppToaster.show({
				message:
					'Prenotazione accettata correttamente, il cliente riceverà una email con le istruzioni per il pagamento della caparra',
			})
		}

		setConfirmReservation(null)
	}

	async function handleDenyReservation(reservation, avoidMail) {
		await dispatch(
			saveReservation(
				{
					...reservation,
					status: ReservationStatus.Denied,
				},
				{ avoidMail },
			),
		)

		AppToaster.show({
			message: avoidMail
				? 'Prenotazione rifiutata correttamente'
				: 'Prenotazione rifiutata correttamente, il cliente riceverà una email di notifica',
		})

		setDenyReservation(null)
	}

	async function handleEditReservation(reservation) {
		await dispatch(saveReservation(reservation))
		AppToaster.show({
			message: `Prenotazione ${
				reservation.id ? 'modificata' : 'aggiunta'
			} correttamente`,
			intent: Intent.SUCCESS,
		})
		setEditReservation(null)
	}

	async function handleCompleteReservation(reservation) {
		await dispatch(
			saveReservation({
				...reservation,
				status: ReservationStatus.Completed,
			}),
		)

		AppToaster.show({
			message: 'Prenotazione completata correttamente',
		})

		setCompleteReservation(null)
	}

	async function handleResendPaymentEmail(reservation) {
		await dispatch(resendPaymentEmailForReservation(reservation.id))

		AppToaster.show({
			message: 'Email di Pagamento inviata correttamente',
		})

		setResendPaymentEmail(null)
	}

	async function handleDeleteReservation(id) {
		await dispatch(deleteReservation(id))

		AppToaster.show({
			message: 'Prenotazione eliminata correttamente',
		})

		setCompleteReservation(null)
	}

	function handleUpdateSortPredicate(predicate) {
		if (predicate === sort.predicate) {
			setSort({
				direction:
					sort.direction === SortDirection.Ascending
						? SortDirection.Descending
						: SortDirection.Ascending,
			})

			return
		}

		setSort({
			predicate,
			direction: SortDirection.Ascending,
		})
	}

	const newReservations = useMemo(() => {
		return reservations.filter(
			(r) =>
				r.status === ReservationStatus.Created &&
				moment(r.reservation_start).isSameOrAfter(
					moment().subtract(10, 'years').startOf('day'),
				),
		)
	}, [reservations])

	const filteredReservations = useFilter(FilterRules, filter, reservations)

	const sortedReservations = useSort(
		filteredReservations,
		sort.predicate,
		sort.direction,
	)

	useEffect(() => {
		setPage(0)
	}, [filter])

	const pagedReservations = useMemo(() => {
		return splitEvery(10, sortedReservations)
	}, [sortedReservations])

	return (
		<div>
			{!user && <Redirect to="/login" />}
			<Navbar className="bp3-dark">
				<NavbarGroup>
					<NavbarHeading>Prenotazioni</NavbarHeading>
				</NavbarGroup>
				<NavbarGroup className="bp3-align-right">
					<Button
						icon="dollar"
						intent={Intent.SUCCESS}
						onClick={handleShowPayments}
					>
						Pagamenti
					</Button>
					<Button icon="log-out" onClick={handleLogout}>
						Esci
					</Button>
				</NavbarGroup>
			</Navbar>
			<Topbar>
				<ButtonGroup>
					<Button
						intent="none"
						icon="reset"
						onClick={handleResetFilter}
					>
						Reimposta Filtro
					</Button>
					<Button
						intent="warning"
						icon="star"
						onClick={handleFilterNewReservations}
					>
						{newReservations.length} Nuove Prenotazioni
					</Button>
					<Button
						intent="primary"
						icon="refresh"
						onClick={handleReloadReservations}
					>
						Ricarica Prenotazioni
					</Button>
					<Button
						intent="success"
						icon="add"
						onClick={() =>
							setEditReservation({
								nationality: 'IT',
								reservation_start: moment().format(
									'YYYY-MM-DD',
								),
								reservation_end: moment()
									.add(1, 'day')
									.format('YYYY-MM-DD'),
							})
						}
					>
						Nuova Prenotazione
					</Button>
				</ButtonGroup>
			</Topbar>
			<Filter filter={filter} onChange={setFilter} />

			<SummaryCards
				reservations={reservations}
				filteredReservations={filteredReservations}
				selectedDate={filter.reservation_start}
				filter={filter}
				onChangeFilter={setFilter}
			/>

			<PaymentListDialog
				isOpen={showPayments}
				onClose={() => setShowPayments(false)}
			/>

			<ConfirmReservationDialog
				isOpen={!!confirmReservation}
				reservation={confirmReservation}
				onClose={() => setConfirmReservation(null)}
				onConfirmReservation={handleConfirmReservation}
				askDeposit={shouldAskForDeposit}
				loading={loading}
			/>
			<DenyReservationDialog
				isOpen={!!denyReservation}
				reservation={denyReservation}
				onClose={() => setDenyReservation(null)}
				onDenyReservation={handleDenyReservation}
				loading={loading}
			/>
			<EditReservationDialog
				isOpen={!!editReservation}
				reservation={editReservation}
				onClose={() => setEditReservation(null)}
				onSaveReservation={handleEditReservation}
				onDeleteReservation={handleDeleteReservation}
				loading={loading}
			/>
			<ConfirmCheckoutDialog
				isOpen={!!completeReservation}
				reservation={completeReservation}
				onClose={() => setCompleteReservation(null)}
				onCheckoutReservation={handleCompleteReservation}
				loading={loading}
			/>
			<ResendPaymentEmailDialog
				isOpen={!!resendPaymentEmail}
				reservation={resendPaymentEmail}
				onClose={() => setResendPaymentEmail(null)}
				onResendEmail={handleResendPaymentEmail}
				loading={loading}
			/>
			<Reservations
				items={pagedReservations}
				page={page}
				onChangePage={(p) => setPage(p)}
				onEditReservation={setEditReservation}
				onRejectReservation={setDenyReservation}
				onConfirmReservation={(i, a) => {
					setConfirmReservation(i)
					setShouldAskForDeposit(a)
				}}
				onResendPaymentEmail={setResendPaymentEmail}
				onCheckout={setCompleteReservation}
				sortPredicate={sort.predicate}
				onUpdateSortPredicate={handleUpdateSortPredicate}
			/>
		</div>
	)
}
