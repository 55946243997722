import React, { useState } from 'react'
import { Dialog, Button, Intent, FormGroup, Checkbox } from '@blueprintjs/core'

export const DenyReservationDialog = ({
	reservation,
	isOpen,
	onClose,
	onDenyReservation,
	loading,
}) => {
	const [avoidMail, setAvoidMail] = useState(false)

	return (
		<Dialog
			title="Rifiuta Prenotazione"
			icon="thumbs-down"
			isOpen={isOpen}
			onClose={onClose}
		>
			<div className="bp3-dialog-body">
				Sei sicuro di voler rifiutare la prenotazione?
			</div>
			<FormGroup style={{ marginLeft: 20 }}>
				<Checkbox
					label="Non inviare email di rifiuto"
					onChange={e => setAvoidMail(e.target.checked)}
					checked={avoidMail}
				/>
			</FormGroup>
			<div className="bp3-dialog-footer">
				<div className="bp3-dialog-footer-actions">
					<Button
						text="Annulla"
						onClick={onClose}
						disabled={loading}
					/>
					<Button
						text="Rifiuta Prenotazione"
						intent={Intent.DANGER}
						onClick={() =>
							onDenyReservation(reservation, avoidMail)
						}
						disabled={loading}
					/>
				</div>
			</div>
		</Dialog>
	)
}
