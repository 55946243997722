import { Button, Card } from '@blueprintjs/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Backdrop } from './backdrop.component'
import { Redirect, useHistory } from 'react-router-dom'
import { logout } from '../../state/auth/auth.effects'

export const Logout = function() {
	const dispatch = useDispatch()
	const user = useSelector(state => state.auth.user)
	const history = useHistory()

	function handleLogout() {
		dispatch(logout())
	}

	function handleBack() {
		history.push('/')
	}

	return (
		<Backdrop>
			{!user && <Redirect to="/login" />}
			<Card elevation={1} style={{ minWidth: 400 }}>
				<h3>Chiudi Sessione</h3>

				<p>Puoi chiudere la sessione cliccando sul pulsante 'Esci'.</p>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: 20,
					}}
				>
					<Button onClick={handleBack}>Annulla</Button>
					<Button
						intent="primary"
						icon="power"
						onClick={handleLogout}
					>
						Esci
					</Button>
				</div>
			</Card>
		</Backdrop>
	)
}
