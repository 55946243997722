export const SET_USER = 'AUTH@SET_USER'

export function setUser(user) {
	return {
		type: SET_USER,
		user,
	}
}

export function userReducer(state = null, action) {
	switch (action.type) {
		case SET_USER: {
			return action.user
		}

		default: {
			return state
		}
	}
}
