import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReservations } from '../use-reservations.hook'
import { useSort, SortDirection } from '../../../common/hooks/use-sort.hook'
import { usePagination } from '../../../common/hooks/use-pagination.hook'
import { Paginator } from '../paginator.component'
import { Dialog, Button, Divider, Intent } from '@blueprintjs/core'
import moment from 'moment'
import { unparse } from 'papaparse'
import { saveAs } from 'file-saver'

export const PaymentListDialog = function ({ isOpen, onClose }) {
	const reservations = useReservations()

	const payedReservations = useMemo(() => {
		return reservations.filter((r) => {
			return !!r.payment_date
		})
	}, [reservations])

	const sortedReservations = useSort(
		payedReservations,
		'payment_date',
		SortDirection.Descending,
	)

	const { pageItems, pageCount, activePage, setActivePage } = usePagination(
		sortedReservations,
		25,
	)

	function handleExport() {
		const data = unparse({
			fields: [
				'ID',
				'Nome',
				'Ospiti',
				'Caparra',
				'Inizio',
				'Fine',
				'Data Pagamento',
			],
			data: sortedReservations.map((r) => [
				r.id,
				r.name,
				r.occupants,
				`${(+r.deposit).toFixed(2)} €`,
				moment(r.reservation_start).format('DD/MM/YYYY'),
				moment(r.reservation_end).format('DD/MM/YYYY'),
				moment(r.payment_date).format('DD/MM/YYYY'),
			]),
		})

		const blob = new Blob([data])
		saveAs(blob, 'pagamenti-export.csv')
	}

	return (
		<Dialog
			isOpen={isOpen}
			onClose={onClose}
			title="Pagamenti"
			icon="dollar"
			style={{ width: '80%' }}
		>
			<table
				className="bp3-html-table bp3-html-table-striped"
				style={{ width: '100%' }}
			>
				<thead>
					<tr>
						<th>ID</th>
						<th>Nome Cliente</th>
						<th>Caparra</th>
						<th>Periodo Prenotazione</th>
						<th>Data Pagamento</th>
					</tr>
				</thead>

				<tbody>
					{pageItems?.map((r) => {
						return (
							<tr key={r.id}>
								<td>{r.id}</td>
								<td>{r.name}</td>
								<td>{r.deposit} €</td>
								<td>
									{moment(r.reservation_start).format(
										'DD/MM/YYYY',
									)}{' '}
									-{' '}
									{moment(r.reservation_end).format(
										'DD/MM/YYYY',
									)}
								</td>
								<td>
									{moment(r.payment_date).format(
										'DD/MM/YYYY',
									)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			<div style={{ margin: 20 }}></div>
			<div
				className="bp3-dialog-footer"
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Paginator
					pageCount={pageCount}
					onSetActivePage={setActivePage}
					activePage={activePage}
				/>
				<div className="bp3-dialog-footer-actions">
					<Button text="Chiudi" onClick={onClose} />
					<Button
						text="Esporta"
						intent={Intent.SUCCESS}
						icon="calculator"
						onClick={handleExport}
					/>
				</div>
			</div>
		</Dialog>
	)
}
