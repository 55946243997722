import React from 'react'
import moment from 'moment'
import { toLower } from 'ramda'
import { printReservationStatusLabel } from '../../common/utils/print-reservation-status-label.util'
import { ReservationStatusIcon } from './reservation-status-item.component'
import {
	Tooltip,
	Icon,
	Position,
	ButtonGroup,
	Intent,
	Menu,
	MenuItem,
	Button,
	Popover,
} from '@blueprintjs/core'
import { ReservationStatus } from '../../common/types/reservation-status.type'
import { Paginator } from './paginator.component'

const showDate = (date) => moment(date).format('DD/MM/YYYY')

const SortableHeader = ({
	children,
	predicate,
	activePredicate,
	onUpdateSortPredicate,
}) => {
	return (
		<th>
			<a
				href="javascript:;"
				onClick={() => onUpdateSortPredicate(predicate)}
			>
				{children}
				{predicate === activePredicate && (
					<Icon style={{ marginLeft: 5 }} icon="sort"></Icon>
				)}
			</a>
		</th>
	)
}

export const Reservations = function ({
	items,
	page,
	sortPredicate,
	onChangePage,
	onEditReservation,
	onRejectReservation,
	onConfirmReservation,
	onResendPaymentEmail,
	onCheckout,
	onUpdateSortPredicate,
}) {
	return (
		<div>
			{items.length === 0 && (
				<div style={{ margin: 10, textAlign: 'center', padding: 20 }}>
					<p className="bp3-text-muted">
						<strong>
							Nessuna prenotazione trovata per il giorno
							selezionato!
						</strong>
					</p>
				</div>
			)}
			{items.length > 0 && (
				<table
					className="bp3-html-table bp3-html-table-striped"
					style={{ width: '100%' }}
				>
					<thead>
						<tr>
							<SortableHeader
								predicate="id"
								activePredicate={sortPredicate}
								onUpdateSortPredicate={onUpdateSortPredicate}
							>
								ID
							</SortableHeader>
							<SortableHeader
								predicate="created_at"
								activePredicate={sortPredicate}
								onUpdateSortPredicate={onUpdateSortPredicate}
							>
								Inserita
							</SortableHeader>
							<SortableHeader
								predicate="reservation_start"
								activePredicate={sortPredicate}
								onUpdateSortPredicate={onUpdateSortPredicate}
							>
								Periodo
							</SortableHeader>
							<SortableHeader
								predicate="occupants"
								activePredicate={sortPredicate}
								onUpdateSortPredicate={onUpdateSortPredicate}
							>
								Ospiti
							</SortableHeader>
							<SortableHeader
								predicate="name"
								activePredicate={sortPredicate}
								onUpdateSortPredicate={onUpdateSortPredicate}
							>
								Nome Cliente
							</SortableHeader>
							<SortableHeader
								predicate="nationality"
								activePredicate={sortPredicate}
								onUpdateSortPredicate={onUpdateSortPredicate}
							>
								Nazione
							</SortableHeader>
							<th>Telefono</th>
							<SortableHeader
								predicate="status"
								activePredicate={sortPredicate}
								onUpdateSortPredicate={onUpdateSortPredicate}
							>
								Stato Prenotazione
							</SortableHeader>
							<th colSpan={4}></th>
						</tr>
					</thead>
					<tbody>
						{items[page]?.map((item) => {
							return (
								<tr key={item.id}>
									<td>
										<Tooltip
											content={
												<>
													{console.log(item)}
													<span>
														Data pagamento:{' '}
														{item.payment_date
															? moment(
																	item.payment_date,
															  ).format(
																	'DD/MM/YYYY',
															  )
															: 'Non Pagata / Non Richiesta'}
													</span>
												</>
											}
											position="right"
										>
											{item.id}
										</Tooltip>
									</td>
									<td>
										{moment(item.created_at).format(
											'DD/MM/YYYY',
										)}
									</td>
									<td>
										{showDate(item.reservation_start)} -{' '}
										{showDate(item.reservation_end)}
									</td>
									<td>{item.occupants}</td>
									<td>{item.name}</td>
									<td>
										<i
											className={`flag-icon flag-icon-${toLower(
												item.nationality,
											)}`}
										/>{' '}
										{item.nationality}
									</td>
									<td>{item.phone}</td>
									<td>
										{printReservationStatusLabel(
											item.status,
										)}
									</td>
									<td>
										{!!item.deposit && (
											<small>{item.deposit} &euro;</small>
										)}
									</td>
									<td>
										{item.notes?.length > 0 && (
											<Tooltip
												content={item.notes}
												position={Position.TOP_RIGHT}
											>
												<Icon icon="info-sign" />
											</Tooltip>
										)}
									</td>
									<td>
										<ReservationStatusIcon
											status={item.status}
											hasDeposit={item.deposit > 0}
										/>
									</td>
									<td>
										<ButtonGroup minimal>
											<Tooltip
												content="Modifica Prenotazione"
												position={Position.LEFT}
											>
												<Button
													intent={Intent.NONE}
													icon="edit"
													onClick={() => {
														onEditReservation(item)
													}}
												/>
											</Tooltip>
											{item.status ===
												ReservationStatus.Created && (
												<>
													<Tooltip content="Rifiuta Prenotazione">
														<Button
															intent={
																Intent.DANGER
															}
															icon="thumbs-down"
															onClick={() => {
																onRejectReservation(
																	item,
																)
															}}
														/>
													</Tooltip>
													<Popover
														position={Position.LEFT}
														content={
															<Menu>
																<MenuItem
																	text="Con Caparra"
																	onClick={() => {
																		onConfirmReservation(
																			item,
																			true,
																		)
																	}}
																/>
																<MenuItem
																	text="Senza Caparra"
																	onClick={() => {
																		onConfirmReservation(
																			item,
																			false,
																		)
																	}}
																/>
															</Menu>
														}
													>
														<Tooltip
															content="Accetta Prenotazione"
															position={
																Position.TOP_RIGHT
															}
														>
															<Button
																intent={
																	Intent.SUCCESS
																}
																icon="thumbs-up"
															/>
														</Tooltip>
													</Popover>
												</>
											)}
											{item.status ===
												ReservationStatus.Accepted && (
												<Tooltip
													content="Rimanda Email di Pagamento"
													position={Position.LEFT}
												>
													<Button
														icon="envelope"
														intent={Intent.WARNING}
														onClick={() => {
															onResendPaymentEmail(
																item,
															)
														}}
													/>
												</Tooltip>
											)}
											{item.status ===
												ReservationStatus.Confirmed && (
												<Tooltip
													content="Checkout"
													position={
														Position.TOP_RIGHT
													}
												>
													<Button
														icon="eject"
														intent={Intent.SUCCESS}
														onClick={() => {
															onCheckout(item)
														}}
													/>
												</Tooltip>
											)}
										</ButtonGroup>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			)}

			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					margin: 20,
				}}
			>
				<Paginator
					activePage={page}
					onSetActivePage={onChangePage}
					pageCount={items?.length ?? 0}
				/>
			</div>
		</div>
	)
}
