import {
	Button,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Position,
} from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'

import { useForm } from '../../../common/hooks/use-form.hook'
import { useValidation } from '../../../common/hooks/use-validation.hook'
import { printReservationStatusLabel } from '../../../common/utils/print-reservation-status-label.util'
import { DeleteReservationDialog } from './delete-reservation-dialog.component'
import {
	MONTHS,
	WEEKDAYS_LONG,
	WEEKDAYS_SHORT,
} from '../../../common/types/locale'

const countries = require('country-list')

const isEmail = v =>
	new RegExp(
		/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/,
	).test(v)

const validateReservationRules = {
	name: v => v && v.length >= 2,
	email: v => v && isEmail(v),
	phone: v => v && v.length >= 5,
	occupants: v => v && Number(v) >= 1 && Number(v) <= 100,
	reservation_end: (v, d) =>
		v && moment(v).isAfter(moment(d.reservation_start)),
	nationality: v => v && v.trim().length > 0,
}

export const EditReservationDialog = function({
	reservation,
	isOpen,
	onClose,
	onSaveReservation,
	onDeleteReservation,
	loading,
}) {
	const [data, onChange] = useForm({})
	const [
		validationStatus,
		{ validate, resetValidationStatus },
	] = useValidation(validateReservationRules)
	const [deleteReservation, setDeleteReservation] = React.useState(null)

	useEffect(() => {
		if (!reservation) {
			return
		}

		onChange(reservation, true)
	}, [reservation])

	async function handleSaveReservation(e) {
		e.preventDefault()
		e.stopPropagation()

		if (!validate(data)) {
			return
		}
		return await onSaveReservation(data)
	}

	useEffect(() => {
		resetValidationStatus()
	}, [data])

	return (
		<>
			<DeleteReservationDialog
				isOpen={!!deleteReservation}
				onClose={() => setDeleteReservation(null)}
				onDeleteReservation={r => (
					onDeleteReservation(r.id),
					setDeleteReservation(null),
					onClose()
				)}
				reservation={reservation}
				loading={loading}
			/>
			<Dialog
				isOpen={isOpen}
				onClose={onClose}
				title="Prenotazione"
				icon="edit"
			>
				<form onSubmit={handleSaveReservation}>
					<div className="bp3-dialog-body">
						<h4>Dati Cliente</h4>
						<FormGroup
							label="Nome e Cognome"
							intent={
								validationStatus.name === false
									? Intent.DANGER
									: Intent.NONE
							}
							helperText={
								validationStatus.name === false &&
								'Campo Obbligatorio'
							}
						>
							<input
								type="text"
								name="name"
								value={data.name || ''}
								onChange={onChange}
								className="bp3-input bp3-fill"
							/>
						</FormGroup>
						<FormGroup
							label="Email"
							intent={
								validationStatus.email === false
									? Intent.DANGER
									: Intent.NONE
							}
							helperText={
								validationStatus.email === false &&
								'Campo Obbligatorio'
							}
						>
							<input
								type="text"
								name="email"
								value={data.email || ''}
								onChange={onChange}
								className="bp3-input bp3-fill"
							/>
						</FormGroup>
						<FormGroup
							label="Telefono"
							intent={
								validationStatus.phone === false
									? Intent.DANGER
									: Intent.NONE
							}
							helperText={
								validationStatus.phone === false &&
								'Campo Obbligatorio'
							}
						>
							<input
								type="text"
								name="phone"
								value={data.phone || ''}
								onChange={onChange}
								className="bp3-input bp3-fill"
							/>
						</FormGroup>
						<FormGroup label="Numero Tessera CAI">
							<input
								type="text"
								name="cai"
								value={data.cai || ''}
								onChange={onChange}
								className="bp3-input bp3-fill"
							/>
						</FormGroup>
						<FormGroup
							label="Nazionalità"
							intent={
								validationStatus.nationality === false
									? Intent.DANGER
									: Intent.NONE
							}
							helperText={
								validationStatus.nationality === false &&
								'Campo Obbligatorio'
							}
						>
							<div className="bp3-select bp3-fill">
								<select
									name="nationality"
									onChange={onChange}
									value={data.nationality || ''}
								>
									{countries.getCodes().map(i => (
										<option value={i} key={i}>
											{countries.getName(i)}
										</option>
									))}
								</select>
							</div>
						</FormGroup>
						<h4>Prenotazione</h4>
						{reservation?.id && (
							<FormGroup label="Stato Prenotazione">
								<div className="bp3-select bp3-fill">
									<select
										name="status"
										onChange={onChange}
										value={data.status || ''}
									>
										{[0, 1, 2, 3, 4, 5].map(i => (
											<option value={i} key={i}>
												{printReservationStatusLabel(i)}
											</option>
										))}
									</select>
								</div>
							</FormGroup>
						)}
						{reservation?.id && (
							<FormGroup label="Caparra">
								<InputGroup
									leftIcon="euro"
									name="deposit"
									onChange={onChange}
									value={data.deposit || ''}
								/>
							</FormGroup>
						)}
						<FormGroup
							label="Data Inizio"
							intent={
								validationStatus.reservation_start === false
									? Intent.DANGER
									: Intent.NONE
							}
							helperText={
								validationStatus.reservation_start === false &&
								'Campo Obbligatorio'
							}
						>
							<DateInput
								value={moment(data.reservation_start).toDate()}
								parseDate={date => moment(date).toDate()}
								formatDate={date =>
									moment(date).format('DD/MM/YYYY')
								}
								className="bp3-fill"
								onChange={date => {
									onChange(
										'reservation_start',
										moment(date).format('YYYY-MM-DD'),
									)

									onChange(
										'reservation_end',
										moment(date)
											.add(1, 'day')
											.format('YYYY-MM-DD'),
									)
								}}
								locale="it"
								dayPickerProps={{
									months: MONTHS,
									weekdaysLong: WEEKDAYS_LONG,
									weekdaysShort: WEEKDAYS_SHORT,
								}}
								format="DD/MM/YYYY"
								minDate={moment()
									.subtract(10, 'years')
									.toDate()}
								maxDate={moment()
									.add(10, 'years')
									.toDate()}
							/>
						</FormGroup>
						<FormGroup
							label="Data Fine"
							intent={
								validationStatus.reservation_end === false
									? Intent.DANGER
									: Intent.NONE
							}
							helperText={
								validationStatus.reservation_end === false &&
								'Campo Obbligatorio'
							}
						>
							<DateInput
								value={moment(data.reservation_end).toDate()}
								parseDate={date => moment(date).toDate()}
								formatDate={date =>
									moment(date).format('DD/MM/YYYY')
								}
								className="bp3-fill"
								onChange={date =>
									onChange(
										'reservation_end',
										moment(date).format('YYYY-MM-DD'),
									)
								}
								locale="it"
								dayPickerProps={{
									months: MONTHS,
									weekdaysLong: WEEKDAYS_LONG,
									weekdaysShort: WEEKDAYS_SHORT,
								}}
								format="DD/MM/YYYY"
								disabled={!data.reservation_start}
								outOfRangeMessage={''}
								minDate={moment(data.reservation_start)
									.add(1, 'day')
									.toDate()}
								maxDate={moment()
									.add(10, 'years')
									.toDate()}
							/>
						</FormGroup>
						<FormGroup
							label="Ospiti"
							intent={
								validationStatus.occupants === false
									? Intent.DANGER
									: Intent.NONE
							}
							helperText={
								validationStatus.occupants === false &&
								'Campo Obbligatorio'
							}
						>
							<input
								type="text"
								name="occupants"
								value={data.occupants || ''}
								onChange={onChange}
								className="bp3-input bp3-fill"
							/>
						</FormGroup>
						<FormGroup label="Note">
							<textarea
								rows={4}
								name="notes"
								onChange={onChange}
								className="bp3-input bp3-fill"
								value={data.notes || ''}
							></textarea>
						</FormGroup>
					</div>
					<div className="bp3-dialog-footer">
						{reservation?.id && (
							<Button
								text="Elimina Prenotazione"
								icon="trash"
								intent={Intent.DANGER}
								style={{ float: 'left' }}
								onClick={() =>
									setDeleteReservation(reservation)
								}
								disabled={loading}
							/>
						)}
						<div className="bp3-dialog-footer-actions">
							<Button
								text="Annulla"
								onClick={onClose}
								disabled={loading}
							/>
							<Button
								text="Salva Prenotazione"
								intent={Intent.SUCCESS}
								onClick={handleSaveReservation}
								disabled={loading}
							/>
						</div>
					</div>
				</form>
			</Dialog>
		</>
	)
}
