import React from 'react'
import { Dialog, Button, Intent } from '@blueprintjs/core'

export const ResendPaymentEmailDialog = ({
	reservation,
	isOpen,
	onClose,
	onResendEmail,
	loading,
}) => (
	<Dialog title="Rimanda Email di pagamento" icon="envelope" isOpen={isOpen} onClose={onClose}>
		<div className="bp3-dialog-body">
			<p>Rimandare Email di pagamento?</p>
		</div>
		<div className="bp3-dialog-footer">
			<div className="bp3-dialog-footer-actions">
				<Button text="Annulla" onClick={onClose} disabled={loading} />
				<Button
					text="Rimanda Email"
					intent={Intent.SUCCESS}
					onClick={() => onResendEmail(reservation)}
					disabled={loading}
				/>
			</div>
		</div>
	</Dialog>
)
