import styled from 'styled-components'
import { Colors } from '@blueprintjs/core'

export const Backdrop = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${Colors.LIGHT_GRAY3};
`
