import { useState } from 'react'
import { validate as _v } from '../utils/validate.util'

export const useValidation = rules => {
	const [validationStatus, setValidationStatus] = useState({})
	const resetValidationStatus = () => setValidationStatus({})

	if (!rules) {
		throw new Error('Rules are required in order to use validation!')
	}

	const validate = data => {
		const { validationStatus, validated } = _v(rules, data)
		setValidationStatus(validationStatus)

		return validated
	}

	return [validationStatus, { validate, resetValidationStatus }]
}
