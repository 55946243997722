import http from 'axios'

import { config } from '../../../core/config.core'
import {
	reservationCreated,
	reservationRemoved,
	reservationsHaveBeenLoaded,
	reservationUpdated,
} from './reservation.state'

export function loadReservations() {
	return async dispatch => {
		const response = await http.get(`${config.server}/shelter/reservations`)

		return dispatch(reservationsHaveBeenLoaded(response.data))
	}
}

export function saveReservation(data, options = {}) {
	return async dispatch => {
		const response = await http.post(
			`${config.server}/shelter/reservations${
				data.id ? `/${data.id}` : ''
			}`,
			data,
			{ params: options },
		)

		if (data.id) {
			dispatch(reservationUpdated(response.data))
		} else {
			dispatch(reservationCreated(response.data))
		}
	}
}

export function deleteReservation(id) {
	return async dispatch => {
		const response = await http.delete(
			`${config.server}/shelter/reservations/${id}`,
		)

		await dispatch(reservationRemoved(id))
	}
}

export function resendPaymentEmailForReservation(id) {
	return async dispatch => {
		return http.post(`${config.server}/shelter/reservations/${id}/resend`)
	}
}
