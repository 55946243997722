import React, { useMemo } from 'react'
import { Card, Colors, Tag, Divider } from '@blueprintjs/core'
import moment from 'moment'
import styled from 'styled-components'
import { ReservationStatus } from '../../common/types/reservation-status.type'
import { printReservationStatusLabel } from '../../common/utils/print-reservation-status-label.util'

const SummaryCard = styled(Card)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 400px;
	align-self: stretch;

	cursor: ${props => (props.onClick ? 'pointer' : 'default')};
	&:hover {
		background-color: ${props => (props.onClick ? Colors.GRAY5 : '#fff')};
	}
`

const SummaryCardsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 10px;
`

const SummaryRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: center;

	div {
		width: 150px;
		margin: 10px;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
`

export const SummaryCards = function({
	reservations,
	filteredReservations,
	selectedDate,
	filter,
	onChangeFilter,
}) {
	const [
		incomingGuests,
		confirmedGuests,
		incomingReservations,
	] = useMemo(() => {
		const incomingReservations = reservations.filter(
			r =>
				r.status !== ReservationStatus.Cancelled &&
				r.status !== ReservationStatus.Denied &&
				moment(r.reservation_start)
					.startOf('day')
					.isSame(moment(selectedDate).startOf('day')),
		)

		const incomingGuests = incomingReservations.reduce(
			(r, n) => r + +n.occupants,
			0,
		)

		const confirmedGuests = incomingReservations
			.filter(r => r.status == ReservationStatus.Confirmed)
			.reduce((r, n) => r + +n.occupants, 0)

		return [incomingGuests, confirmedGuests, incomingReservations.length]
	}, [reservations, selectedDate])

	const [
		totalGuests,
		totalConfirmedGuests,
		totalReservations,
	] = useMemo(() => {
		const totalReservations = reservations.filter(r => {
			const s = moment(selectedDate).startOf('day')

			return (
				r.status !== ReservationStatus.Denied &&
				r.status !== ReservationStatus.Cancelled &&
				s.isSameOrAfter(moment(r.reservation_start).startOf('day')) &&
				s.isBefore(moment(r.reservation_end).startOf('day'))
			)
		})

		const totalGuests = totalReservations.reduce(
			(r, n) => r + +n.occupants,
			0,
		)

		const totalConfirmedGuests = totalReservations
			.filter(r => r.status == ReservationStatus.Confirmed)
			.reduce((r, n) => r + +n.occupants, 0)

		return [totalGuests, totalConfirmedGuests, totalReservations.length]
	}, [reservations, selectedDate])

	function handleSetShowTotalReservationsFilter() {
		onChangeFilter({
			...filter,
			date_exclusive: false,
			show_period: true,
		})
	}

	function handleRemoveFilterDate() {
		onChangeFilter(
			{
				...filter,
				reservation_start: moment()
					.startOf('day')
					.toDate(),
				reservation_end: moment()
					.startOf('day')
					.add(1, 'day')
					.toDate(),
			},
			true,
		)
	}

	function handleRemoveFilterStatus() {
		onChangeFilter(
			{
				...filter,
				status: ReservationStatus.All,
			},
			true,
		)
	}

	function handleRemoveFilterDateExclusive() {
		onChangeFilter(
			{
				...filter,
				date_exclusive: true,
			},
			true,
		)
	}

	function handleRemoveFilterShowPeriod() {
		onChangeFilter(
			{
				...filter,
				show_period: false,
			},
			true,
		)
	}

	return (
		<SummaryCardsContainer>
			<SummaryCard>
				<h6
					className="bp3-heading"
					style={{ color: Colors.GRAY1, fontSize: 12 }}
				>
					Giorno del {moment(selectedDate).format('DD/MM/YYYY')}
				</h6>
				<h4 className="bp3-heading">Ospiti in arrivo</h4>

				<SummaryRow>
					<div>
						<h1 className="bp3-heading">{incomingGuests}</h1>
						<h5
							className="bp3-heading"
							style={{ color: Colors.GRAY1, fontSize: 14 }}
						>
							su {incomingReservations} prenotazioni
						</h5>
					</div>

					<div>
						<h1
							className="bp3-heading"
							style={{ color: Colors.GREEN1 }}
						>
							{confirmedGuests}
						</h1>
						<h5
							className="bp3-heading"
							style={{ color: Colors.GREEN1, fontSize: 14 }}
						>
							confermati
						</h5>
					</div>
				</SummaryRow>
			</SummaryCard>
			<SummaryCard onClick={handleSetShowTotalReservationsFilter}>
				<h6
					className="bp3-heading"
					style={{ color: Colors.GRAY1, fontSize: 12 }}
				>
					Giorno del {moment(selectedDate).format('DD/MM/YYYY')}
				</h6>
				<h4 className="bp3-heading">Ospiti totali</h4>

				<SummaryRow>
					<div>
						<h1 className="bp3-heading">{totalGuests}</h1>
						<h5
							className="bp3-heading"
							style={{ color: Colors.GRAY1, fontSize: 14 }}
						>
							su {totalReservations} prenotazioni
						</h5>
					</div>
					<div>
						<h1
							className="bp3-heading"
							style={{ color: Colors.GREEN1 }}
						>
							{totalConfirmedGuests}
						</h1>
						<h5
							className="bp3-heading"
							style={{ color: Colors.GREEN1, fontSize: 14 }}
						>
							confermati
						</h5>
					</div>
				</SummaryRow>
			</SummaryCard>
			<SummaryCard>
				<h4 className="bp3-heading">Filtro Attivo</h4>

				<h1 className="bp3-heading">{filteredReservations.length}</h1>
				<h5
					className="bp3-heading"
					style={{ color: Colors.GRAY1, fontSize: 14 }}
				>
					prenotazioni
				</h5>
				{!moment(filter.reservation_start)
					.startOf('day')
					.isSame(moment().startOf('day')) && (
					<Tag onRemove={handleRemoveFilterDate}>
						Data:{' '}
						{moment(filter.reservation_start).format('DD/MM/YYYY')}
					</Tag>
				)}

				{filter.status !== ReservationStatus.All && (
					<Tag onRemove={handleRemoveFilterStatus}>
						{printReservationStatusLabel(filter.status)}
					</Tag>
				)}
				{!filter.date_exclusive && !filter.show_period && (
					<Tag onRemove={handleRemoveFilterDateExclusive}>
						Tutti i giorni nell'intervallo
					</Tag>
				)}

				{filter.show_period && (
					<Tag onRemove={handleRemoveFilterShowPeriod}>
						Se{' '}
						{moment(filter.reservation_start).format('DD/MM/YYYY')}{' '}
						è nel periodo di prenotazione
					</Tag>
				)}
			</SummaryCard>
		</SummaryCardsContainer>
	)
}
