export const ReservationStatus = {
	All: -1,

	Created: 0,
	Denied: 1,
	Accepted: 2,
	Confirmed: 3,
	Cancelled: 4,
	Completed: 5,
}
